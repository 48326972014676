import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { withPrefix } from 'gatsby'

const SecondPage = () => (
  <Layout>
    <SEO title="MHM 2000" />

    <h1>MHM 2000</h1>

    <p>
      MHM 2000 sai alkunsa MHM 99:n röyhkeänä kausipäivityksenä, jota ryhdyin
      ajattelemaan keväällä ja kesällä 1999. Heräsin tajuamaan että
      tulevaisuudensuunnitelmani olivat ihan liian avoinna ja taloudellinen
      tilateeni huono. Vetäisin pelille hatusta julkaisupäivän, tekaisin
      alkukuvan ja pulttasin cupin pelin sisuksiin.
    </p>

    <p>
      Cup synnytti tukun uusia joukkueita, jotka eivät oikeastaan asuneet
      missään. Ne esiintyivät vain cupissa, mikä oli typerää. Niinpä koodasin
      mutasarjan, kolmannen sarjatason PHL:n (liiga) ja divisioonan (nykyinen
      mestis) rinnalle.
    </p>

    <p>
      Mutasarjan lisäys tämä rikkoi MHM 99:n jo valmiiksi huteran ja herkän
      tasapainon, ja päätin korjata sen. Itse asiassa moni muukin asia olisi
      voinut olla paremmin, ja korjasin niitäkin. Korjaukset johtivat uusiin
      korjauksiin, jotka tekivät alkuperäisen julkaisuaikataulun mahdottomaksi
      noudattaa.
    </p>

    <p>
      Samaan aikaan sain onnekseni opiskelupaikan ja otin sen tällä kertaa jopa
      vastaan. Vaikka Turun ammattikorkeakoulu ei jääneet muistoihini parhaana
      mahdollisina paikkana, minulla on tämän päätöksen jälkeen mennyt
      elämässäni paremmin.
    </p>

    <p>
      Päätin hylätä alkuperäisen suunnitelmani päivityksestä, ja MHM 2000:sta
      sai luvan tulla trilogian täydentävä täysivaltainen kolmas osa. Uudessa
      kodissani Loimaalla koodasin koodaamasta päästyäni, enkä luovuttanut
      vaikka välillä näytti siltä että kakstonnisesta ei tule ikinä valmista.
    </p>

    <p>
      Helmikuun lopussa 2000 peli putkahti maailmaan, ei siksi että se oli
      valmis, vaan koska julkaisijan ruokakaappi ammotti tyhjyyttään. Pikapätsit
      korjasivat pahimmat aukot, ja 1.2 -version myötä peli saavutti lopullisen
      olomuotonsa.
    </p>

    <p>
      Minulla itse asiassa <em>on</em> vielä yksi päivitys, versio 1.3. En
      oikein tiedä, miksen koskaan julkaissut sitä. En saanut
      kehitysympäristöäni toimimaan Windows kakstonnisessa, ja muistan hämärästi
      etten saanut betatestattua pätsiä loppuun. Huono tekosyy, tiedän. Ehkä
      toteutan 1.3-pätsin loppuun nyt kun remasteroin koko roskan herran vuonna
      2019.
    </p>

    <p>
      Päätellen MHM 2000:n saamasta euforisesta vastaanotosta pelaajat olivat
      tyytyväisiä. Niin olin minäkin, ja se koitui turmiokseni. Joka kerta, kun
      yritin tarttua aiheeseen, minusta tuntui ettei minulla enää ollut
      sanottavaa. Minua ahdisti ajatus siitä, ettei uusi MHM onnistuisi yhtä
      hyvin. Sairastuin toisin sanoen pahimmanlaatuiseen pelintekijän blokkiin,
      jos sellaista edes on.
    </p>

    <p>
      Keskityin veppiin; opettelin HTML:n, CSS:n, relaatiotietokantoja,
      Javascriptiä, PHP:tä ynnä muita webin teknologioita. Se, mitä olin oppinut
      MHM:ää suunnitellessani ja ohjelmoidessani, osoittautui hyödylliseksi ja
      sain lopulta koodaamisesta ammatin. Siirtymä PHP:hen oli itseoppineelle
      Basic-miehelle aika looginen; PHP:n ystävällinen asenne aloittelijoita ja
      harrastajia kohtaan oli siinä vaiheessa minulle tärkeää.
    </p>

    <p>
      Vuodet ovat tästä vierineet. Pyörittelin uutta MHM:ää mielessäni aika
      ajoin, mutta tekeminen tyssäsi aina johonkin. Aloin olla aika varma siitä,
      ettei uutta MHM:ää koskaan tule. Alkuvuonna 2008 kirjoitin Pelit-lehteen
      viimeisen sanani asiaa koskien, itsetutkiskelevan ja -puhdistavan
      artikkelin. Päästin ensimmäistä kertaa kokonaan irti MHM:ää koskevista
      ajatuksistani ja keskityin muihin asioihin.
    </p>

    <p>
      Mutta tämä ei ollut loppu. Rattaat pyörähtivät lopulta uuteen asentoon,
      jossa aloitin "MHM Online"-projektin, verkossa moninpelattavan MHM:n.
      Inspiraationi palasi. Erilaiset suunnitelmat, joita olin vuosien varrella
      tehnyt, löysivät toisensa ja muodostivat uuden, yhtenäisen vision uudesta,
      uljaasta MHM:stä.
    </p>

    <p>
      Aika oli koittanut. Päätös syntyi. MHM:n rautanyrkki oli iskevä jälleen.
      Vain vähän myöhemmin päätös syntyi projektin hautaamisesta, ainoastaan
      pulpahtaakseen esille vähän myöhemmin. Tämä sykli toistui, kunnes lopulta
      minua hävettivät pettämäni lupaukset niin paljon, että annoin MHM:n
      kotisivujenkin kuolla.
    </p>

    <h2>Pelaa</h2>

    <p>
      Huomaathan, että tämä ei todellakaan ole <strong>valmis</strong>. Laitoin
      sen joka tapauksessa buildaamaan joka kerta kun töräytän koodia masteriin,
      joten pelin tila on niin ajantasainen kuin se voi olla!
      <a target="_blank" href="https://mhm2000.mhm-online.org">
        Pelaa MHM 2000:n uusioversiota.
      </a>
    </p>

    <p>
      Omallekin koneelle saat kakstonnisen uusioversion pyöriskelemään{' '}
      <a target="_blank" href="https://github.com/mhm-online/mhm-2000-remake">
        lataamalla sen Githubista
      </a>
      !
    </p>

    <h2>Lataa</h2>

    <p>
      MHM 2000 on DOS-peli. Tarvitset{' '}
      <a target="_blank" href="https://www.dosbox.com/">
        Dosboxin
      </a>{' '}
      pelataksesi sitä.
    </p>

    <ul>
      <li>
        <a href={withPrefix('/files/mhm2kfw.zip')}>MHM 2000 Freeware</a>
      </li>
    </ul>

    <Link to="/">Takaisin</Link>
  </Layout>
)

export default SecondPage
